import React, {
    FC,
} from 'react';

import GreyStuff from './GreyStuff';

import letterIImage from './media/letterI.svg';
import letterTImage from './media/letterT.svg';
import netImage from './media/net.png';
import redHatchImage from './media/redHatch.svg';

import styles from './Background.module.scss';

const Background: FC = () => {
    return (
        <>
            <img
                className={styles.letterI}
                src={letterIImage}
                alt={''}
            />
            <img
                className={styles.letterT}
                src={letterTImage}
                alt={''}
            />
            <img
                className={styles.redHatch}
                src={redHatchImage}
                alt={''}
            />
            <img
                className={styles.net}
                src={netImage}
                alt={''}
            />
            <GreyStuff/>
        </>
    );
};

export default Background;
