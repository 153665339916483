import {
    IRef,
} from './types';

import {
    useDefaultedRef,
} from 'tools/hooks';

export function useFileInputRef() {
    return useDefaultedRef<IRef>({
        getValue(): File | null {
            return null;
        },
        clearValue() {
            return;
        },
        validate(): boolean {
            return true;
        },
    });
}
