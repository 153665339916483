import React, {
    FC,
} from 'react';

import RedStuff from './RedStuff';

import greyLine1Image from './media/greyLine1.svg';

import styles from './Background.module.scss';

const Background: FC = () => {
    return (
        <>
            <img
                className={styles.greyLine1}
                src={greyLine1Image}
                alt={''}
            />
            <RedStuff/>
        </>
    );
};

export default Background;
