import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

import styles from './Client.module.scss';

const Client: FC<IProps> = (props) => {
    return (
        <div className={styles.client}>
            <div className={styles.number}>
                {props.number}
            </div>
            <div className={styles.logo}>
                <img
                    src={props.logo}
                    alt={''}
                />
            </div>
            <div className={styles.name}>
                {props.children}
            </div>
        </div>
    );
};

export default Client;
