import React, {
    FC,
} from 'react';
import {
    scroller,
} from 'react-scroll';

import BurgerMenu from './BurgerMenu';

import {
    BLOCK_NAME,
} from '../constants';

import styles from './Header.module.scss';

const Header: FC = () => {
    const onScrollTo = (blockName: BLOCK_NAME) => {
        scroller.scrollTo(blockName, {
            smooth: true,
        });
    };

    return (
        <div className={styles.header}>
            <div className={styles.burger}>
                <BurgerMenu/>
            </div>
            <span className={styles.logo}>
                IT SPORT
            </span>
            <nav className={styles.nav}>
                <div className={styles.navItem}>
                    <span onClick={() => onScrollTo(BLOCK_NAME.SERVICE)}>
                        Услуги
                    </span>
                </div>
                <div className={styles.navItem}>
                    <span onClick={() => onScrollTo(BLOCK_NAME.WORK)}>
                        Как мы работаем
                    </span>
                </div>
                <div className={styles.navItem}>
                    <span onClick={() => onScrollTo(BLOCK_NAME.CLIENTS)}>
                        Наши клиенты
                    </span>
                </div>
                <div className={styles.navItem}>
                    <span onClick={() => onScrollTo(BLOCK_NAME.PROJECT)}>
                        Проекты в разработке
                    </span>
                </div>
            </nav>
        </div>
    );
};

export default Header;
