import {
    IProps,
    IRef,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    checkUrl,
} from 'tools/functions';

import {
    StringInput,
} from 'components/helpers/inputs';

import {
    ERROR,
} from './constants';
import {
    formatError,
} from './functions';

const SiteInput = forwardRef<IRef, IProps>((props, ref) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState<ERROR | null>(null);

    const onChange = (value: string) => {
        setValue(value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): string {
                return value.trim();
            },
            clearValue(): void {
                setValue('');
            },
            validate(): boolean {
                const formattedValue = value.trim();

                if (!formattedValue) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }
                if (!checkUrl(value)) {
                    setError(ERROR.INVALID_FORMAT);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <StringInput
            value={value}
            placeholder={'Ваш сайт (или ссылка в соц. сетях)*'}
            isError={!!error}
            errorMessage={formatError(error)}
            onChangeValue={onChange}
        />
    );
});

export default SiteInput;
