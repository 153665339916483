import {
    IRef,
} from './types';

import {
    useDefaultedRef,
} from 'tools/hooks';

import {
    ERROR,
} from './constants';

export function useEmailInputRef() {
    return useDefaultedRef<IRef>({
        getValue(): string {
            return '';
        },
        clearValue() {
            return;
        },
        validate(): boolean {
            return true;
        },
    });
}

export function formatError(error: ERROR | null): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return 'Обязательное поле';
        }
        case ERROR.INVALID_FORMAT: {
            return 'Требуется почта';
        }
        case null: {
            return '';
        }
    }
}
