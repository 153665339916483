import React, {
    FC,
} from 'react';

import ColoredImage from './ColoredImage';
import GreyStuff from './GreyStuff';

import grayCorner1Image from './media/gray-corner1.svg';
import grayCorner2Image from './media/gray-corner2.svg';
import grayCorner3Image from './media/gray-corner3.svg';
import grayCorner4Image from './media/gray-corner4.svg';
import grayLine1Image from './media/grayLine1.svg';
import grayLine2Image from './media/grayLine2.svg';
import netImage from './media/net.png';
import peopleBg1Image from './media/people-bg1.png';
import peopleBg2Image from './media/people-bg2.png';
import peopleBg3Image from './media/people-bg3.png';
import people1Image from './media/people1.png';
import people2Image from './media/people2.png';
import people3Image from './media/people3.png';
import symbolsImage from './media/symbols.png';

import styles from './Background.module.scss';

const Background: FC = () => {
    return (
        <>
            <ColoredImage
                image={people1Image}
                background={peopleBg1Image}
                className={styles.people1}
            />
            <ColoredImage
                image={people2Image}
                background={peopleBg2Image}
                className={styles.people2}
            />
            <ColoredImage
                image={people3Image}
                background={peopleBg3Image}
                className={styles.people3}
            />
            <img
                className={styles.grayLine1}
                src={grayLine1Image}
                alt={''}
            />
            <img
                className={styles.grayLine2}
                src={grayLine2Image}
                alt={''}
            />
            <img
                className={styles.net}
                src={netImage}
                alt={''}
            />
            <img
                className={styles.symbols}
                src={symbolsImage}
                alt={''}
            />
            <img
                className={styles.grayCorner1}
                src={grayCorner1Image}
                alt={''}
            />
            <img
                className={styles.grayCorner2}
                src={grayCorner2Image}
                alt={''}
            />
            <img
                className={styles.grayCorner3}
                src={grayCorner3Image}
                alt={''}
            />
            <img
                className={styles.grayCorner4}
                src={grayCorner4Image}
                alt={''}
            />
            <GreyStuff/>
        </>
    );
};

export default Background;
