import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './WorkCard.module.scss';

const WorkCard: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.workCard, props.className)}>
            <div className={styles.title}>
                <span>
                    {props.title}
                </span>
            </div>
            <div className={styles.content}>
                <div className={styles.number}>
                    {props.number}
                </div>
                <div className={styles.description}>
                    {props.children}
                </div>
            </div>
        </div>

    );
};

export default WorkCard;
