import React, {
    FC,
    useState,
} from 'react';

import Email from 'helpers/Email';

import Background from './Background';
import RequestForm, {
    useRequestFormRef,
} from './RequestForm';

import styles from './FormBlock.module.scss';

const FormBlock: FC = () => {
    const [isPending, setIsPending] = useState(false);

    const requestFormRef = useRequestFormRef();

    const onSubmit = async () => {
        if (isPending) {
            return;
        }
        if (!requestFormRef.current.validate()) {
            return;
        }

        const body = requestFormRef.current.getValue();

        setIsPending(true);

        const res = await Email.send(body);

        setIsPending(false);

        if (res.error) {
            return;
        }

        alert('Форма успешно отправлена!');
        requestFormRef.current.clearValue();
    };

    return (
        <div className={styles.formBlock}>
            <div className={styles.title}>
                Нужен
                {' '}
                <span className={styles.red}>
                    проект?
                </span>
            </div>
            <div className={styles.description}>
                Оставьте заявку, либо звоните,
                мы пообщаемся и сами все за вас заполним:
                {' '}
                <span className={styles.bold}>
                    +7 (812) 748-24-49
                </span>
            </div>
            <RequestForm
                ref={requestFormRef}
                onSubmit={onSubmit}
            />
            <Background/>
        </div>
    );
};

export default FormBlock;
