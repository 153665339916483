type IAcceptType = 'image' | 'video' | 'audio' | 'file' | 'any';

export default function(acceptTypes: IAcceptType[]): Promise<File | null> {
    return new Promise<File | null>((resolve) => {
        const input = document.createElement('input');
        let accept = '';

        acceptTypes.forEach((acceptType) => {
            switch (acceptType) {
                case 'image': {
                    accept += '.jpg,.jpeg,.png;';
                    break;
                }
                case 'video': {
                    accept += '.mp4,.mov;';
                    break;
                }
                case 'audio': {
                    accept += '.mp3';
                    break;
                }
                case 'file': {
                    accept += '.doc,.docx,.xls,.xlsxx,.ppt,.pptx,.rtf,.pdf,.psd,.djvu,.fb2;';
                    break;
                }
                case 'any': {
                    break;
                }
            }
        });

        input.type = 'file';
        input.accept = accept;
        input.onchange = (e) => {
            // @ts-ignore
            resolve(e.target?.files?.[0] || null);
        };

        input.click();
    });
}
