import {
    IProps,
    IRef,
} from './types';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    chooseFile,
} from 'helpers/functions';

import addImage from './media/add.svg';

import styles from './FileInput.module.scss';

const FileInput = forwardRef<IRef, IProps>((props, ref) => {
    const [value, setValue] = useState<File | null>(null);

    const onChoose = async () => {
        const file = await chooseFile(['any']);

        setValue(file);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): File | null {
                return value;
            },
            clearValue(): void {
                setValue(null);
            },
            validate(): boolean {
                return true;
            },
        };
    });

    return (
        <button
            className={classNames(styles.buttonForm, props.className)}
            onClick={onChoose}
        >
            {value ? value.name : props.placeholder}
            <img
                src={addImage}
                alt={''}
            />
        </button>
    );
});

export default FileInput;
