import {
    IProps,
    IRef,
    IValue,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
} from 'react';

import {
    PrimaryButton,
} from 'components/helpers/buttons';
import {
    FileInput,
    useFileInputRef,
} from 'components/helpers/inputs';

import EmailInput, {
    useEmailInputRef,
} from './EmailInput';
import NameInput, {
    useNameInputRef,
} from './NameInput';
import OrganizationInput, {
    useOrganizationInputRef,
} from './OrganizationInput';
import PhoneInput, {
    usePhoneInputRef,
} from './PhoneInput';
import SiteInput, {
    useSiteInputRef,
} from './SiteInput';

import styles from './RequestForm.module.scss';

const RequestForm = forwardRef<IRef, IProps>((props, ref) => {
    const nameInputRef = useNameInputRef();
    const phoneInputRef = usePhoneInputRef();
    const emailInputRef = useEmailInputRef();
    const organizationInputRef = useOrganizationInputRef();
    const siteInputRef = useSiteInputRef();
    const fileInputRef = useFileInputRef();

    useImperativeHandle(ref, () => {
        return {
            getValue(): IValue {
                return {
                    name: nameInputRef.current.getValue(),
                    phone: phoneInputRef.current.getValue(),
                    email: emailInputRef.current.getValue(),
                    organization: organizationInputRef.current.getValue(),
                    site: siteInputRef.current.getValue(),
                    file: fileInputRef.current.getValue(),
                };
            },
            clearValue(): void {
                nameInputRef.current.clearValue();
                phoneInputRef.current.clearValue();
                emailInputRef.current.clearValue();
                organizationInputRef.current.clearValue();
                siteInputRef.current.clearValue();
                fileInputRef.current.clearValue();
            },
            validate(): boolean {
                return ![
                    nameInputRef.current.validate(),
                    phoneInputRef.current.validate(),
                    emailInputRef.current.validate(),
                    organizationInputRef.current.validate(),
                    siteInputRef.current.validate(),
                    fileInputRef.current.validate(),
                ].includes(false);
            },
        };
    });

    return (
        <div className={styles.requestForm}>
            <div className={styles.inputsGroup}>
                <NameInput ref={nameInputRef}/>
                <PhoneInput ref={phoneInputRef}/>
                <EmailInput ref={emailInputRef}/>
                <OrganizationInput ref={organizationInputRef}/>
                <SiteInput ref={siteInputRef}/>
                <FileInput
                    ref={fileInputRef}
                    placeholder={'Прикрепить файл'}
                />
            </div>
            <PrimaryButton
                className={styles.sendButton}
                onClick={props.onSubmit}
            >
                Отправить
            </PrimaryButton>
            <div className={styles.warning}>
                Нажимая на кнопку, вы даете согласие на
                {' '}
                <span className={styles.bold}>
                    обработку персональных данных
                </span>
            </div>
        </div>
    );
});

export default RequestForm;
