import React, {
    FC,
    useState,
} from 'react';

import Menu from './Menu';

import burgerIcon from './media/burgerIcon.svg';

import styles from './BurgerMenu.module.scss';

const BurgerMenu: FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={styles.burgerMenu}>
            <div
                className={styles.icon}
                onClick={() => setIsOpen(!isOpen)}
            >
                <img
                    src={burgerIcon}
                    alt={''}
                />
            </div>
            <Menu
                className={isOpen ? styles.isOpen : styles.isClose}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </div>
    );
};

export default BurgerMenu;
