import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';
import {
    scroller,
} from 'react-scroll';

import {
    BLOCK_NAME,
} from '../../../constants';

import arrowIcon from './media/arrow.svg';

import styles from './Menu.module.scss';

const Menu: FC<IProps> = (props) => {
    const onScrollTo = (blockName: BLOCK_NAME) => {
        scroller.scrollTo(blockName, {
            smooth: true,
        });
        props.setIsOpen(false);
    };

    return (
        <div className={classNames(styles.menu, props.className)}>
            <div
                className={styles.arrow}
                onClick={() => props.setIsOpen(!props.isOpen)}
            >
                <img
                    src={arrowIcon}
                    alt={''}
                />
            </div>
            <nav className={styles.nav}>
                <div className={styles.navItem}>
                    <span onClick={() => onScrollTo(BLOCK_NAME.SERVICE)}>
                        Услуги
                    </span>
                </div>
                <div className={styles.navItem}>
                    <span onClick={() => onScrollTo(BLOCK_NAME.WORK)}>
                        Как мы работаем
                    </span>
                </div>
                <div className={styles.navItem}>
                    <span onClick={() => onScrollTo(BLOCK_NAME.CLIENTS)}>
                        Наши клиенты
                    </span>
                </div>
                <div className={styles.navItem}>
                    <span onClick={() => onScrollTo(BLOCK_NAME.PROJECT)}>
                        Проекты в разработке
                    </span>
                </div>
            </nav>
        </div>
    );
};

export default Menu;
