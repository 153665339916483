import React, {
    FC,
} from 'react';

import Background from './Background';
import ServiceCard from './ServiceCard';

import ServiceCard1Icon from './media/icon1.svg';
import ServiceCard2Icon from './media/icon2.svg';
import ServiceCard3Icon from './media/icon3.svg';
import ServiceCard4Icon from './media/icon4.svg';

import styles from './ServiceBlock.module.scss';

const ServiceBlock: FC = () => {
    return (
        <div className={styles.serviceBlock}>
            <div className={styles.main}>
                <div className={styles.title}>
                    Услуги
                </div>
                <div className={styles.description}>
                    <p>
                        IT Sport - команда экспертов
                        <br/>
                        в спортивном Digital.
                    </p>
                    <p>
                        Наши клиенты - крупнейшие спортивные
                        <br/>
                        организации - федерации, лиги,
                        <br/>
                        организаторы мероприятий, которые
                        <br/>
                        меняют спорт с помощью технологий.
                    </p>
                </div>
                <div className={styles.upperGrayText}>
                    IT SPORT / CYBER 01
                </div>
            </div>
            <div className={styles.cardsContainer}>
                <div className={styles.cards}>
                    <ServiceCard
                        className={styles.serviceCard1}
                        icon={ServiceCard1Icon}
                    >
                        Создание сайтов
                    </ServiceCard>
                    <ServiceCard
                        className={styles.serviceCard2}
                        icon={ServiceCard2Icon}
                    >
                        Мобильная разработка
                    </ServiceCard>
                    <ServiceCard
                        className={styles.serviceCard3}
                        icon={ServiceCard3Icon}
                    >
                        Разработка систем управления
                        <br/>
                        соревнованиями и статистикой
                    </ServiceCard>
                    <ServiceCard
                        className={styles.serviceCard4}
                        icon={ServiceCard4Icon}
                    >
                        Техническая поддержка
                    </ServiceCard>
                </div>
                <div className={styles.lowerGrayText}>
                    IT SPORT / CYBER 01
                </div>
            </div>
            <Background/>
        </div>
    );
};

export default ServiceBlock;
