import React, {
    FC,
} from 'react';

import Background from './Background';

import arrowImage from './media/arrow.svg';

import styles from './MainBlock.module.scss';

const MainBlock: FC = () => {
    return (
        <div className={styles.mainBlock}>
            <div className={styles.upperTextSection}>
                <span className={styles.upperDate}>
                    01
                    <br/>
                    /
                    <br/>
                    20
                    <br/>
                    22
                </span>
                <span className={styles.red}>
                    CYBER
                </span>
                {' '}
                <span className={styles.gray}>
                    SPORT
                </span>
            </div>
            <div className={styles.titleContainer}>
                <div className={styles.title}>
                    <span className={styles.red}>
                        IT
                    </span>
                    {' '}
                    РЕШЕНИЕ
                    <br/>
                    ДЛЯ СПОРТА
                </div>
            </div>
            <div className={styles.centralTextSection}>
                <div className={styles.line}></div>
                <span className={styles.lowerDate}>
                    01
                    <br/>
                    /
                    <br/>
                    20
                    <br/>
                    22
                </span>
            </div>
            <div className={styles.bottomTextSection}>
                <span className={styles.white}>
                    ПОДРОБНЕЕ
                </span>
                <div className={styles.arrow}>
                    <img
                        src={arrowImage}
                        alt={''}
                    />
                </div>
            </div>
            <Background/>
        </div>
    );
};

export default MainBlock;
