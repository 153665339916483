import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './ServiceCard.module.scss';

const ServiceCard: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.serviceCard, props.className)}>
            <img
                className={styles.icon}
                src={props.icon}
                alt={''}
            />
            <div className={styles.description}>
                {props.children}
            </div>
        </div>

    );
};

export default ServiceCard;
