import {
    IRef,
    IValue,
} from './types';

import {
    useDefaultedRef,
} from 'tools/hooks';

export function useRequestFormRef() {
    return useDefaultedRef<IRef>({
        getValue(): IValue {
            return {
                name: '',
                phone: '',
                email: '',
                organization: '',
                site: '',
                file: null,
            };
        },
        clearValue() {
            return;
        },
        validate(): boolean {
            return true;
        },
    });
}
