import React, {
    FC,
} from 'react';

import styles from './GreyStuff.module.scss';

const GreyStuff: FC = () => {
    return (
        <div className={styles.s1}/>
    );
};

export default GreyStuff;
