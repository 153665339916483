import React, {
    FC,
} from 'react';

import GreyStuff from './GreyStuff';
import RedStuff from './RedStuff';

import device1Image from './media/device1.png';
import device2Image from './media/device2.png';
import redHatchImage from './media/redHatch.svg';

import styles from './Background.module.scss';

const Background: FC = () => {
    return (
        <>
            <img
                className={styles.device1}
                src={device1Image}
                alt={''}
            />
            <img
                className={styles.device2}
                src={device2Image}
                alt={''}
            />
            <img
                className={styles.redHatch}
                src={redHatchImage}
                alt={''}
            />
            <GreyStuff/>
            <RedStuff/>
        </>
    );
};

export default Background;
