import React, {
    FC,
} from 'react';

import atomImage from './media/atom.png';

import styles from './Background.module.scss';

const Background: FC = () => {
    return (
        <img
            className={styles.atom}
            src={atomImage}
            alt={''}
        />
    );
};

export default Background;
