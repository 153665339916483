import React, {
    FC,
} from 'react';

import Background from './Background';
import Client from './Client';

import client1Logo from './media/client1.svg';
import client2Logo from './media/client2.png';
import client3Logo from './media/client3.png';

import styles from './ClientsBlock.module.scss';

const ClientsBlock: FC = () => {
    return (
        <div className={styles.clientsBlock}>
            <div className={styles.title}>
                НАШИ КЛИЕНТЫ
            </div>
            <div className={styles.clients}>
                <Client
                    number={'01'}
                    logo={client1Logo}
                >
                    Российская киберспортивная лига
                </Client>
                <Client
                    number={'02'}
                    logo={client2Logo}
                >
                    Киберспортивная организация “Drift Gaming”
                </Client>
                <Client
                    number={'03'}
                    logo={client3Logo}
                >
                    Магазин цифровой и бытовой техники “DNS”

                </Client>
            </div>
            <Background/>
        </div>
    );
};

export default ClientsBlock;
