import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    ChangeEvent,
    FC,
} from 'react';

import styles from './StringInput.module.scss';

const StringInput: FC<IProps> = (props) => {
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.onChangeValue?.(e.target.value);
        props.onChange?.(e);
    };

    return (
        <div>
            <input
                className={
                    classNames(styles.inputForm, props.className, {
                        [styles.isError]: props.isError,
                    })
                }
                value={props.value}
                placeholder={props.placeholder}
                onChange={onChange}
                onFocus={props.onFocus}
                onBlur={props.onBlur}
            />
            {
                !!props.errorMessage &&
                <div className={styles.errorMessage}>
                    {props.errorMessage}
                </div>
            }
        </div>
    );
};

export default StringInput;
