import React, {
    FC,
} from 'react';
import {
    Element,
} from 'react-scroll';

import ClientsBlock from './ClientsBlock';
import Footer from './Footer';
import FormBlock from './FormBlock';
import Header from './Header';
import MainBlock from './MainBlock';
import ProjectBlock from './ProjectBlock';
import ServiceBlock from './ServiceBlock';
import WorkBlock from './WorkBlock';

import {
    BLOCK_NAME,
} from './constants';

import styles from './Home.module.scss';

const Home: FC = () => {
    return (
        <div className={styles.home}>
            <Header/>
            <div className={styles.content}>
                <MainBlock/>
                <Element name={BLOCK_NAME.SERVICE}>
                    <ServiceBlock/>
                </Element>
                <Element name={BLOCK_NAME.WORK}>
                    <WorkBlock/>
                </Element>
                <Element name={BLOCK_NAME.CLIENTS}>
                    <ClientsBlock/>
                </Element>
                <Element name={BLOCK_NAME.PROJECT}>
                    <ProjectBlock/>
                </Element>
                <FormBlock/>
            </div>
            <Footer/>
        </div>
    );
};

export default Home;
