import React, {
    FC,
} from 'react';

import styles from './RedStuff.module.scss';

const RedStuff: FC = () => {
    return (
        <>
            <div className={styles.s1}/>
            <div className={styles.line}>
                <div className={styles.s2}/>
                <div className={styles.s3}/>
                <div className={styles.s4}/>
            </div>
        </>
    );
};

export default RedStuff;
