import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './PrimaryButton.module.scss';

const PrimaryButton: FC<IProps> = (props) => {
    return (
        <div
            className={classNames(styles.primaryButton, props.className)}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    );
};

export default PrimaryButton;
