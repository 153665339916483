import React, {
    FC,
} from 'react';

import ProjectCard from './ProjectCard';
import RedStuff from './RedStuff';

import card1Icon from './media/icon1.svg';
import card2Icon from './media/icon2.svg';
import card3Icon from './media/icon3.svg';

import styles from './ProjectBlock.module.scss';

const ProjectBlock: FC = () => {
    return (
        <div className={styles.projectBlock}>
            <div className={styles.title}>
                ПРОЕКТЫ В&nbsp;РАЗРАБОТКЕ
            </div>
            <div className={styles.whiteLine}></div>
            <div className={styles.cards}>
                <ProjectCard
                    icon={card1Icon}
                >
                    Автоматизация
                    <br/>
                    киберспортивной
                    <br/>
                    лиги
                </ProjectCard>
                <ProjectCard
                    icon={card2Icon}
                >
                    Личный кабинет
                    <br/>
                    киберспортсмена
                </ProjectCard>
                <ProjectCard
                    icon={card3Icon}
                >
                    Образовательная
                    <br/>
                    спортивная
                    <br/>
                    платформа
                </ProjectCard>
            </div>
            <RedStuff/>
        </div>
    );
};

export default ProjectBlock;
