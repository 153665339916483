import {
    IProps,
    IRef,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    useInputMask,
} from 'tools/hooks';

import {
    StringInput,
} from 'components/helpers/inputs';

import {
    ERROR,
    MASK,
} from './constants';
import {
    formatError,
} from './functions';

const OrganizationInput = forwardRef<IRef, IProps>((props, ref) => {
    const [error, setError] = useState<ERROR | null>(null);

    const {
        value,
        setValue,
        inputProps,
    } = useInputMask({
        value: '',
        mask: MASK,
        withCapitalization: true,
        onChange() {
            setError(null);
        },
    });

    useImperativeHandle(ref, () => {
        return {
            getValue(): string {
                return value.trim();
            },
            clearValue(): void {
                setValue('');
            },
            validate(): boolean {
                const formattedValue = value.trim();

                if (!formattedValue) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <StringInput
            className={props.className}
            value={value}
            placeholder={'Название вашей организации*'}
            isError={!!error}
            errorMessage={formatError(error)}
            {...inputProps}
        />
    );
});

export default OrganizationInput;
