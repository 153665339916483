import React, {
    FC,
} from 'react';

import styles from './RedStuff.module.scss';

const RedStuff: FC = () => {
    return (
        <div className={styles.s1}/>
    );
};

export default RedStuff;
