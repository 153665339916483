import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

import styles from './ProjectCard.module.scss';

const ProjectCard: FC<IProps> = (props) => {
    return (
        <div className={styles.projectCard}>
            <div className={styles.icon}>
                <img
                    src={props.icon}
                    alt={''}
                />
            </div>
            <div className={styles.description}>
                {props.children}
            </div>
        </div>
    );
};

export default ProjectCard;
