import React, {
    FC,
} from 'react';

import Background from './Background';
import WorkCard from './WorkCard';

import styles from './WorkBlock.module.scss';

const WorkBlock: FC = () => {
    return (
        <div className={styles.workBlock}>
            <div className={styles.titleBlock}>
                <div className={styles.title}>
                    КАК МЫ РАБОТАЕМ
                </div>
                <div className={styles.redText}>
                    WORK / 02
                </div>
            </div>
            <div className={styles.cards}>
                <WorkCard
                    className={styles.workCard}
                    title={'Встреча'}
                    number={'1'}
                >
                    Встречаемся лично или в ZOOM/Skype
                    для обсуждения целей и границ проекта.
                    Обычно достаточно одной встречи
                    на 1-2 часа.
                </WorkCard>
                <WorkCard
                    className={styles.workCard}
                    title={'Планирование'}
                    number={'2'}
                >
                    Определяем первичные рамки бюджета
                    и сроков, собираем вместе с клиентом
                    требования к проекту, разбиваем проект
                    на этапы и итерации. В среднем это 3-5 дней.
                </WorkCard>
                <WorkCard
                    className={styles.workCard}
                    title={'Техзадание (проектные решения)'}
                    number={'3'}
                >
                    Разрабатываем Техническое задание
                    и проектные решения, рисуем макеты
                    будущего проекта, описываем необходимые
                    функции и интеграции. Определяем точный
                    объем работ - бюджет и сроки. Разработка
                    проектных решений в среднем занимает
                    3-6 недель.
                </WorkCard>
                <WorkCard
                    className={styles.workCard}
                    title={'Разработка'}
                    number={'4'}
                >
                    Реализуем задуманное в проектных
                    решениях: разрабатываем дизайн, верстаем,
                    программируем, интегрируем с внешними
                    системами, тестируем, запускаем (внедряем,
                    обучаем, наполняем контентом). В нашем
                    портфолио проекты длительностью от 1 до
                    24 месяцев.
                </WorkCard>
                <WorkCard
                    className={styles.workCard5}
                    title={'Техподдержка и развитие'}
                    number={'5'}
                >
                    После запуска проекта мы продолжаем
                    его развивать, добавлять новые
                    возможности, поддерживать
                    работоспособность, решать возникающие
                    вопросы. Стоимость зависит от ежемесячного
                    объема выполняемых работ.
                </WorkCard>
            </div>
            <Background/>
        </div>
    );
};

export default WorkBlock;
