import {
    IAttachment,
} from './types';
import {
    ISendBody,
} from './types/requests';
import {
    ISend,
} from './types/responses';

import config from 'config';

import Sender from './Sender';

import {
    fileToBase64,
} from '../functions';

class Email {
    public static async send(body: ISendBody): Promise<ISend> {
        const attachments: IAttachment[] = [];

        if (body.file) {
            attachments.push({
                name: body.file.name,
                data: await fileToBase64(body.file),
            });
        }

        const res = await Sender.send({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Host: config.EMAIL_HOST,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Username: config.EMAIL_LOGIN,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Password: config.EMAIL_PASSWORD,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            To: config.EMAIL_LOGIN,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            From: config.EMAIL_LOGIN,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Subject: 'New request',
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Body: `
                Имя: ${body.name}\n
                Телефон: ${body.phone}\n
                Почта: ${body.email}\n
                Название организации: ${body.organization}\n
                Сайт: ${body.site}
            `,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Attachments: attachments,
        });

        if (res !== 'OK') {
            console.log(res);

            return {
                error: res,
            };
        }

        return {};
    }
}

export default Email;
